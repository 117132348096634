<template>
  <div class="col-md-7 mx-auto">
    <div class="card shadow-lg rounded">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Valores predeterminados para PQRS</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Clase de documento (*):</label>
              <select2 id="tcr_id" v-model="item.tcr_id" :options="tpocors" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Tipo de documento (*):</label>
              <select2 id="tdo_id" v-model="item.tdo_id" :options="tpodocs" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-8">
              <label>Tipo de tabla (*):</label>
              <select2 id="tta_id" v-model="item.tta_id" :options="tpotablas" required />
            </div>
            <div class="form-group col-md-4">
              <label>Versión de tabla (*):</label>
              <input type="number" v-model="item.tav_nmro" class="form-control">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Dependencia (*):</label>
              <select2 id="dep_id" v-model="item.dep_id" :options="dependencias" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Serie (*):</label>
              <select2 id="ser_id" v-model="item.ser_id" :options="series" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Tipo de archivo (*):</label>
              <select2 id="tpa_id" v-model="item.tpa_id" :options="tpoarchs" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Usuario (*):</label>
              <select2 id="usu_id" v-model="item.usu_id" :options="usuarios" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label>Tiempo de respuesta (*):</label>
              <input type="number" v-model="item.dcm_tres" class="form-control">
            </div>
            <div class="form-group col-md-8">
              <label>Enviar copia de solicitud a correo electrónico:</label>
              <input type="email" v-model="item.pqr_email_cco" class="form-control">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Url de Pqrs:</label>
              <input type="text" v-model="item.pqr_urlpqrs" class="form-control">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Url de la política de tratamiento de datos personales:</label>
              <input type="text" v-model="item.pqr_urlptdp" class="form-control">
            </div>
          </div>
          <div class="text-center">
            <p class="msg">{{ message }}</p>
          </div>
          <div class="row text-center">
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import Select2 from '../select2/Select2.vue';

  export default {
    components: {
      Select2
    },
    data(){
      return{
        add: true,
        item: {},
        tpocors: [],
        tpodocs: [],
        tpotablas: [],
        dependencias: [],
        series: [],
        tpoarchs: [],
        usuarios: [],
        message: '',
        ready: false
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      //console.log(JSON.stringify(this.$store.state));
      //debugger;
      this.fetchTpocor();
      this.fetchTpodoc();
      this.fetchTpotabla();
      this.fetchDependencia();
      this.fetchSerie();
      this.fetchTpoarch();
      this.fetchUsuario();

      this.getItem();
    },
    methods: {
      fetchTpocor(){
        let uri = '/tpocors/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.tcr_nmbre + ' (' + a.tcr_cdgo + ')').toLowerCase(); 
            var tb = String(b.tcr_nmbre + ' (' + b.tcr_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.tpocors = list.map(i => ({ id: String(i._id), text: i.tcr_nmbre + ' (' + i.tcr_cdgo + ')' }));
        });
      },
      fetchTpodoc(){
        let uri = '/tpodocs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.tdo_nmbre + ' (' + a.tdo_cdgo + ')').toLowerCase(); 
            var tb = String(b.tdo_nmbre + ' (' + b.tdo_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.tpodocs = list.map(i => ({ id: String(i._id), text: i.tdo_nmbre + ' (' + i.tdo_cdgo + ')' }));
        });
      },
      fetchTpotabla(){
        let uri = '/tpotablas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.tta_nmbre + ' (' + a.tta_cdgo + ')').toLowerCase(); 
            var tb = String(b.tta_nmbre + ' (' + b.tta_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.tpotablas = list.map(i => ({ id: String(i._id), text: i.tta_nmbre + ' (' + i.tta_cdgo + ')' }));
        });
      },
      fetchDependencia(){
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.dep_nmbre + ' (' + a.dep_cdgo + ')').toLowerCase(); 
            var tb = String(b.dep_nmbre + ' (' + b.dep_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.dependencias = list.map(i => ({ id: String(i._id), text: i.dep_nmbre + ' (' + i.dep_cdgo + ')' }));
        });
      },
      fetchSerie(){
        let uri = '/series/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.ser_nmbre + ' (' + a.ser_cdgo + ')').toLowerCase(); 
            var tb = String(b.ser_nmbre + ' (' + b.ser_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.series = list.map(i => ({ id: String(i._id), text: i.ser_nmbre + ' (' + i.ser_cdgo + ')' }));
        });
      },
      fetchTpoarch(){
        let uri = '/tpoarchs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.tpa_nmbre + ' (' + a.tpa_cdgo + ')').toLowerCase(); 
            var tb = String(b.tpa_nmbre + ' (' + b.tpa_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.tpoarchs = list.map(i => ({ id: String(i._id), text: i.tpa_nmbre + ' (' + i.tpa_cdgo + ')' }));
        });
      },
      fetchUsuario(){
        let uri = '/usuarios/list';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, { cmp_id: String(this.$store.state.company) })
        .then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.usu_nmbre + ' ' + a.usu_aplldo + ' (' + a.usu_cdgo + ')').toLowerCase(); 
            var tb = String(b.usu_nmbre + ' ' + b.usu_aplldo + ' (' + b.usu_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.usuarios = list.map(i => ({ id: String(i._id), text: i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }));
        });
      },
      saveItem(){      
        if (this.add){
          this.item.cmp_id = String(this.$store.state.company);
          let uri = '/pqrs/add';
          this.axios.post(uri, this.item)
          .then(response => {
            this.add = false;
            this.message = 'Información guardada.';
          })
          .catch(err => {
            this.message = 'Error al guardar la información.';
          });
        } else {
          let uri = '/pqrs/update/' + String(this.$store.state.company);
          this.axios.post(uri, this.item)
          .then((response) => {
            this.message = 'Información guardada.';
          })
          .catch((err) => {
            this.message = 'Error al guardar la información.';
          });
        }
      },
      getItem(){
        let uri = '/pqrs/edit/' + String(this.$store.state.company);
        this.axios.get(uri)
        .then((response) => {
          if (response.data){
            this.add = false;
            this.item = response.data;
          } else {
            this.add = true;
          }
        });
      }
    }
  }
  </script>

  <style>
    .msg {
      color: #ff0000;
      background-color: white;
    }
  </style>
